<template>
  <section class="menu">
    <div class="menu-wrap">
      <div class="main-menu__header">
        <div class="main-menu__caption">Фильтры</div>
      </div>
      <div class="main-menu__button d-md-none" @click="menu_visible.push(-1)">
        <div class="main-menu__button-text">Выбрать раздел</div>
        <div class="main-menu__button-arrow">
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 11L6.75 6L1.75 1"
              stroke="#56565A"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
      </div>
      <div class="main-menu" :class="{'main-menu_visible': menu_visible.length}">
        <div class="main-menu__close d-md-none" @click="menu_visible = []">
          <div class="main-menu__close-text">Закрыть</div>
          <div class="main-menu__close-icon">
            <svg
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.24508 6.5L13.2284 1.51571C13.4588 1.29521 13.5519 0.967575 13.4717 0.659124C13.3915 0.350673 13.1506 0.109578 12.8419 0.0287828C12.5331 -0.0520119 12.2047 0.0401128 11.9833 0.269642L7 5.25393L2.01668 0.269642C1.67012 -0.0619967 1.1215 -0.0558617 0.782462 0.283444C0.443426 0.622749 0.4386 1.17051 0.771603 1.51571L5.75392 6.5L0.771603 11.4843C0.541174 11.7048 0.448112 12.0324 0.528295 12.3409C0.608477 12.6493 0.849381 12.8904 1.15813 12.9712C1.46689 13.052 1.79527 12.9599 2.01668 12.7304L7 7.74607L11.9833 12.7304C12.2047 12.9599 12.5331 13.052 12.8419 12.9712C13.1506 12.8904 13.3915 12.6493 13.4717 12.3409C13.5519 12.0324 13.4588 11.7048 13.2284 11.4843L8.24508 6.5Z"
                fill="#0A467E"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.24508 6.5L13.2284 1.51571C13.4588 1.29521 13.5519 0.967575 13.4717 0.659124C13.3915 0.350673 13.1506 0.109578 12.8419 0.0287828C12.5331 -0.0520119 12.2047 0.0401128 11.9833 0.269642L7 5.25393L2.01668 0.269642C1.67012 -0.0619967 1.1215 -0.0558617 0.782462 0.283444C0.443426 0.622749 0.4386 1.17051 0.771603 1.51571L5.75392 6.5L0.771603 11.4843C0.541174 11.7048 0.448112 12.0324 0.528295 12.3409C0.608477 12.6493 0.849381 12.8904 1.15813 12.9712C1.46689 13.052 1.79527 12.9599 2.01668 12.7304L7 7.74607L11.9833 12.7304C12.2047 12.9599 12.5331 13.052 12.8419 12.9712C13.1506 12.8904 13.3915 12.6493 13.4717 12.3409C13.5519 12.0324 13.4588 11.7048 13.2284 11.4843L8.24508 6.5Z"
                fill="#0A467E"
              ></path>
            </svg>
          </div>
        </div>
          <div class="menu-item" :class="{'menu-item_opened': menu_visible.includes(0)}">
            <div class="menu-item__header" @click="openMenu(0)">
                <div class="menu-item__name">
                    <div class="menu-item__arrow">
                      <v-icon>
                        mdi-chevron-down
                      </v-icon>
                    </div>
                    <div>Дата показа</div>
                </div>
            </div>
            <div class="menu-item__list">
              <v-text-field
                v-model="dateRangeText"
                label="Выберите дату"
                append-icon="mdi-calendar"
                readonly
                outlined
                :disabled="date_empty"
                hide-details="true"
                background-color="#FFFFFF"
                @focus="openCalendar = true"
              ></v-text-field>
              <v-checkbox
                    v-model="date_empty"
                    :ripple="false"
                    label="Дата не указана"
                    color="#008FC5"
                    hide-details
              ></v-checkbox>

              <div v-if="openCalendar" class="date-range-wrapper custom-header-color">
                <v-date-picker v-model="dates"
                  color="#008FC5"
                  header-color="#008FC5"
                  width="275"
                  no-title
                  elevation="2"
                  range>
                  <v-col>
                    <v-btn elevation="0"
                      color="#0A467E"
                      width="100%"
                      tile dark
                      :disabled="!!!dates.length"
                      @click.stop="openCalendar = false">
                        Сохранить
                    </v-btn>

                    <v-btn class="mt-2"
                      color="#0A467E"
                      width="100%"
                      outlined tile
                      @click.stop="dates = []; openCalendar = false">
                        Сбросить
                    </v-btn>
                  </v-col>
                </v-date-picker>
              </div>
            </div>
          </div>

          <div v-if="$route.name !== 'PromoCarousel'"
            class="menu-item " 
            :class="{'menu-item_opened': menu_visible.includes(1)}">
            <div class="menu-item__header" @click="openMenu(1)">
                <div class="menu-item__name">
                    <div class="menu-item__arrow">
                      <v-icon>
                        mdi-chevron-down
                      </v-icon>
                    </div>
                    <div>Видимость</div>
                </div>
            </div>
            <div class="menu-item__list">
                <v-checkbox
                v-for="i in userTypes"
                    :key="i.id"
                    v-model="user_types"
                    :ripple="false"
                    :label="i.name"
                    color="#008FC5"
                    :value="`${i.id}`"
                    hide-details
                ></v-checkbox>
            </div>
          </div>

          <div class="menu-item " :class="{'menu-item_opened': menu_visible.includes(2)}">
            <div class="menu-item__header" @click="openMenu(2)">
                <div class="menu-item__name">
                    <div class="menu-item__arrow">
                      <v-icon>
                        mdi-chevron-down
                      </v-icon>
                    </div>
                    <div>Статус</div>
                </div>
            </div>
            <div class="menu-item__list">
              <v-radio-group v-if="$route.name !== 'PromoCarousel'"
                v-model="status" 
                column> 
                  <v-radio
                    :ripple="false"
                    name="status"
                    label="Любой"
                    color="#008FC5"
                    :value="{is_test: null, is_public: null}"
                    hide-details
                  ></v-radio>
                  <v-radio :ripple="false"
                    name="status"
                    label="На проверке"
                    color="#008FC5"
                    :value="{is_test: 'true', is_public: 'false'}"
                    hide-details
                  ></v-radio>
                  <v-radio
                    :ripple="false"
                    name="status"
                    label="Опубликовано"
                    color="#008FC5"
                    :value="{is_test: 'true', is_public: 'true'}"
                    hide-details
                  ></v-radio>
                  <v-radio
                    :ripple="false"
                    name="status"
                    label="Не проверен"
                    color="#008FC5"
                    :value="{is_test: 'false', is_public: 'false'}"
                    hide-details
                  ></v-radio>
              </v-radio-group>

              <v-radio-group v-else 
                v-model="is_active">
                  <v-radio
                    :ripple="false"
                    name="status"
                    label="Любой"
                    color="#008FC5"
                    :value="null"
                    hide-details
                  ></v-radio>
                  <v-radio :ripple="false"
                    name="status"
                    label="На проверке"
                    color="#008FC5"
                    :value="'false'"
                    hide-details
                  ></v-radio>
                  <v-radio
                    :ripple="false"
                    name="status"
                    label="Опубликовано"
                    color="#008FC5"
                    :value="'true'"
                    hide-details
                  ></v-radio>
              </v-radio-group>
            </div>
          </div>

          <div v-if="$route.name !== 'PromoCarousel'"
            class="menu-item " 
            :class="{'menu-item_opened': menu_visible.includes(3)}">
            <div class="menu-item__header" @click="openMenu(3)">
                <div class="menu-item__name">
                    <div class="menu-item__arrow">
                      <v-icon>
                        mdi-chevron-down
                      </v-icon>
                    </div>
                    <div>Категория</div>
                </div>
            </div>

            <div class="menu-item__list">
                <v-checkbox v-for="i in Categories"
                  :key="i.id"
                  v-model="categories"
                  :ripple="false"
                  :label="i.name"
                  color="#008FC5"
                  :value="`${i.id}`"
                  hide-details
                ></v-checkbox>
            </div>
          </div>
      </div>

      <v-btn elevation="0"
        color="#0A467E"
        dark
        tile
        block
        :ripple="false"
        height="44"
        :disabled="disableClear"
        @click="clearFilter()"
        class="mt-4" >
          Очистить фильтр
      </v-btn>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "AsideFilter",
  data: (vm) => ({
    user_types: vm.$route.query.available_for ? typeof(vm.$route.query.available_for) === 'string' ? vm.$route.query.available_for.split(',') : vm.$route.query.available_for[0].split(',') : [],
    menu_visible: [],
    dates: [],
    date_empty: (vm.$route.query.end_date__isnull === 'true' && vm.$route.query.start_date__isnull === 'true'),
    status: {
      is_test: vm.$route.query.is_test || '',
      is_public: vm.$route.query.is_public || ''
    },
    is_active: vm.$route.query.is_active || null,
    categories: vm.$route.query.category ? typeof(vm.$route.query.category) === 'string' ? vm.$route.query.category.split(',') : vm.$route.query.category[0].split(',') : [],
    openCalendar: false,
  }),
  components: {
  }, 
  computed: {
    ...mapGetters(['userTypes', 'Categories', 'getFilter']),
    dateRangeText () {
        return this.formatDate.join(' – ')
    },
    disableClear() {
      return !(this.status && (this.user_types.length > 0 || this.categories.length > 0 ||this.dates.length > 0 || this.status.is_test || this.status.is_public || this.date_empty))
    },
    formatDate() {
        return (this.dates && this.dates.length)
        ? [this.$moment(this.dates[0]).format("L"), this.$moment(this.dates[1]).format("L")]
        : [];
    },
    filter() {
      let obj = {};
      if ( this.$route.name !== 'PromoCarousel' ) {
        obj = {
          is_test: this.status ? this.status.is_test : '',
          is_public: this.status ? this.status.is_public : '',
          available_for: this.user_types.join(','),
          date_from: this.dates[0] || '',
          date_to: this.dates[1] || '',
          category: this.categories.join(',')
        };
      } else {
        obj = {
          is_active: this.is_active,
          date_from: this.dates[0] || '',
          date_to: this.dates[1] || '',
        };
      }
      
      if ( this.date_empty ) {
        this.dates = []
        obj.end_date__isnull = this.date_empty.toString()
        obj.start_date__isnull = this.date_empty.toString()
      }

      this.addFilter(obj);
      return obj;
    }
  },
  methods: {
    ...mapActions(['fetchUserTypes', 'fetchCategories', 'addFilter']),
    openMenu(id) {
      if(this.menu_visible.includes(id)) {
        this.menu_visible.splice(this.menu_visible.findIndex((el) => el === id), 1);
      } else {
        this.menu_visible.push(id)
      }
    },
    clearFilter() {
      this.user_types = [];
      this.dates = []
      this.status = null;
      this.date_empty = false;
      this.categories = []
    },
    buildQuery() {
      let query = {};
      for ( let key in this.filter ) {
        if ( this.filter[key] !== '' && this.filter[key] !== null ) {
          query[key] = this.filter[key]
        }
      }
      
      if ( this._.isEqual(this.$route.query, query) ) return;
      this.$router.replace({ query });
    },

  },
  async mounted() {
    await this.fetchUserTypes();
    await this.fetchCategories();
    if(this.$route.query.date_from) {
      this.dates.push(this.$route.query.date_from)
      this.openMenu(0)
    }
    if(this.$route.query.date_to) {
      this.dates.push(this.$route.query.date_to)
      this.openMenu(0)
    }
    if(this.$route.query.start_date__isnull && this.$route.query.end_date__isnull) {
      this.openMenu(0)
    }
    if(this.user_types.length) {
      this.openMenu(1)
    }
    if(this.categories.length) {
      this.openMenu(3)
    }
    if(this.status.is_test || this.status.is_public || this.is_active !== null) {
      this.openMenu(2)
    }
  },
  watch: {
    is_active() {
      this.buildQuery();
    },
    status: function() {
      this.buildQuery();
    },
    user_types() {
      this.buildQuery();
    },
    dates() {
       this.buildQuery();
    },
    categories() {
      this.buildQuery();
    },
    date_empty() {
      this.buildQuery();
    },
    $route() {
      this.user_types = this.$route.query.available_for ? 
        typeof(this.$route.query.available_for) === 'string' ? 
          this.$route.query.available_for.split(',') : 
        this.$route.query.available_for[0].split(',') : 
      [];

      this.categories = this.$route.query.category ? 
        typeof(this.$route.query.category) === 'string' ? 
          this.$route.query.category.split(',') : 
        this.$route.query.category[0].split(',') : 
      [];
      // this.date_empty = this.$route.query.end_date__isnull === 'true' && this.$route.query.start_date__isnull === 'true'
    },
    filter() {
      this.addFilter(this.filter);
    }
  }
};
</script>
    

<style scoped lang="scss">
    @charset "utf-8";
/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
/* END RESET */

/* BASE */
.menu-wrap {
    background-color: #F7F7F7;
    padding: 22px 15px;
    @media screen and (max-width: 960px) {
      padding-right: 0;
    }
    @media screen and (max-width: 600px) {
      padding-right: 15px;
    }
}

.main-menu {
  &__caption {
    margin-bottom: 30px;
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
    color: #56565A;
      @media screen and (max-width: 960px) {
          padding-bottom: 0;
          margin-bottom: 15px;
          font-size: 24px;
          line-height: 28px;
          border-bottom: 0;
      }
  }

  &__header {
    position: sticky;
    top: 0;
    // background-color: #fff;
    z-index: 2;
  }

  &__button {
      padding: 19px 22px;
      margin-bottom: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #F7F7F7;
  }

  &__button-text {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #56565A;
  }

  &__button-arrow {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  &__close {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    padding: 25px 30px 0;
    width: calc(100% + 60px);
    margin-bottom: 15px;
    margin-left: -30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
  }

  &__close-text {
    margin-right: 8px;
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #0A467E;
  }

  &__close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @media screen and (max-width: 960px) {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    padding: 0 30px 25px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
    @media screen and (max-width: 560px) {
      padding: 47px 30px 25px;
    }

    @media screen and (max-width: 960px) {
      padding: 75px 30px 25px;
    }
  }

  &_visible {
    @media screen and (max-width: 960px) {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 300ms;
    }

    @media screen and ( max-width: 600px ) {
      overflow-x: hidden;
    }
  }
}

.main.column-view {
  .main-menu {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    padding: 0 30px 25px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
    cursor: pointer;
      &__caption {
          border-bottom: 0;
          padding-bottom: 0;
      }
      &_visible {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 300ms;

          @media screen and ( max-width: 600px ) {
            overflow-x: hidden;
          }
      }
  }
}

.main-menu { 
    .menu-item {
      padding-bottom: 15px;
      margin-bottom: 30px;
      margin-left: 0;
      border-bottom: 1px solid #DADADA;
        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
            margin: 0 auto;
        }

        &__calendar {
          position: absolute;
          top: 100%;
          width: calc(100% - 36px);
          margin-top: 10px;
          z-index: 3;
          background-color: #fff;
          padding: 15px;
          border: 1px solid #DADADA;
          border-radius: 3px;
        }
        &__header {
            font-family: "Source Sans Pro", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 19px;
            line-height: 24px;
            color: #56565A;
            &:hover {
                cursor: pointer;
                color: #0A467E;
                transition: .3s all;
            }

            & > a {
                font-family: inherit;
                font-style: inherit;
                font-weight: inherit;
                font-size: inherit;
                line-height: inherit;
                color: inherit;
                text-decoration: none;
            }
        }

        &__document {
          font-weight: normal !important;
        }

        .menu-item {
          .menu-item__header {
            font-size: 17px;
            line-height: 21px;
            font-weight: 600;
          }

           .menu-item {
            .menu-item__header  {
              font-weight: normal;
            }
          }
        }
    }
}

.menu-item {
    margin-left: 45px;
    & .v-icon {
      color: currentColor;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        /* cursor: pointer; */
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #56565A;
        cursor: pointer;

        &:hover .project__menu {
          opacity: 0.7;
        }
    }

    &__name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }

    &__arrow {
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .3s;
        cursor: pointer;
    }

    &__list {
        max-height: 0;
        overflow: hidden;
        transition: .3s;
        padding-left: 36px;
        position: relative;
    }
}

.menu-item_current {
    > .menu-item__header {
        color: #0A467E;
        font-weight: 600;
    }
}

.menu-item_opened {
    > .menu-item {
        &__list {
            max-height: 5000px;
            overflow: visible;
            border-left: 1px solid #DADADA;
        }
        &__header {
            font-weight: 600;
            color: #0A467E;
            > .menu-item__name .menu-item__arrow {
                transform: rotate(180deg);
            }
        }
    }
}
</style>


